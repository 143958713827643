var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalWrapper',{ref:"modalWrapper",class:{
        'rates-price-history-popup': true,
        'rates-price-history-popup--json': _vm.showRawData,
    },style:({
        '--no-rates-message': _vm.isCheapestChannel
            ? `'${ _vm.$t('rates.cheapestChannelGraphNotAvailable') }'`
            : `'${ _vm.$t('rates.noRatesWereDetected') }'`,
    }),attrs:{"options":_vm.modalOptions},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('RatesPriceHistoryHeader')]},proxy:true},(!_vm.showRawData)?{key:"main",fn:function(){return [_c('div',{staticClass:"rates-price-history-popup__measure-container"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"main__header"},[_c('RatesPriceHistoryFilters',{attrs:{"isCluster":_vm.isCluster}})],1),(_vm.isAnalysisPage)?_c('div',{staticClass:"comparison-filter"},[_c('span',[_vm._v(_vm._s(_vm.$t(`rates.comparisonTo`)))]),_c('span',{staticClass:"comparison-filter__selected",domProps:{"textContent":_vm._s(_vm.compareLabel)}})]):_vm._e(),(!_vm.isCheapestChannel)?_c('div',{class:{
                        'main__graph': true,
                        'main__graph--empty': _vm.isNoData || _vm.isCheapestChannel,
                        'skeleton-container': _vm.skeleton,
                    }},[(_vm.chartData)?_c('CustomGraph',{class:{ skeleton: _vm.skeleton },attrs:{"type":'line',"isTooltip":!!_vm.chartData.labels.length,"haveFixedTooltip":true,"options":_vm.options,"chartData":_vm.chartData,"defaultTooltipPos":_vm.lastTooltipPos,"onTooltipClick":_vm.handleTooltipClick},on:{"current-day":_vm.setTooltipDay,"setDay":_vm.setDay,"tooltip-state-changed":_vm.setTooltipElement},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('div',{staticClass:"price-history-tooltip"})]},proxy:true}],null,false,1274193594)}):_c('div',{staticClass:"graph-placeholder",class:{'skeleton': _vm.skeleton}}),(!_vm.skeleton)?_c('p',{staticClass:"last-scan"},[_vm._v(" "+_vm._s(_vm.$t('lastUpdate'))+" ")]):_vm._e()],1):_vm._e(),_c('RatesPriceHistoryTooltip',{attrs:{"focusElement":_vm.tooltipFocusElement,"day":_vm.tooltipDay,"ignoreHotels":_vm.hiddenGraphs,"priceShown":_vm.priceShown}}),_c('div',{class:{
                        'main__table-measure-container': true,
                        'content-wrapper': true,
                    }},[_c('div',{staticClass:"main__table"},[(_vm.isAllChannelsMode)?_c('RatesPriceHistoryTableAll',{attrs:{"hiddenGraphs":_vm.hiddenGraphs,"priceShown":_vm.priceShown,"tableDay":_vm.tableDay},on:{"toggle-hotel":_vm.toggleGraph}}):_c('RatesPriceHistoryTable',{attrs:{"hiddenGraphs":_vm.hiddenGraphs,"priceShown":_vm.priceShown,"tableDay":_vm.tableDay,"doc":_vm.doc},on:{"toggle-hotel":_vm.toggleGraph}})],1)])],1)]),_c('div',{staticClass:"rates-price-history-popup__sidebar"},[_c('PopupEventsContainer',{attrs:{"day":+_vm.day}}),_c('PriceHistoryStatistics',_vm._b({},'PriceHistoryStatistics',{
                doc: _vm.doc,
                tableDay: _vm.tableDay,
                isCluster: _vm.isCluster,
                priceShown: _vm.priceShown,
                skeleton: _vm.skeleton,
                chartData: _vm.chartData,
            },false))],1)]},proxy:true}:{key:"main",fn:function(){return [_c('div',{staticClass:"main main--json"},[_c('JsonViewer',{attrs:{"value":_vm.doc || {},"day":_vm.day}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"footer"},[(_vm.isSuperadmin)?_c('a',{on:{"click":function($event){_vm.showRawData = !_vm.showRawData}}},[_vm._v(" "+_vm._s(_vm.showRawData ? _vm.$t('popup.showNormal') : _vm.$t('popup.showRaw'))+" ")]):_vm._e(),_c('TogglePrices',{model:{value:(_vm.priceShown),callback:function ($$v) {_vm.priceShown=$$v},expression:"priceShown"}})],1)]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }