
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { ChartData, ChartDataSets, ChartOptions } from 'chart.js';

import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RatesPriceHistoryService, { RatesPriceHistoryServiceS } from '@/modules/rates/price-history/rates-price-history.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import ClusterDiLiteService, { ClusterDiLiteServiceS } from '@/modules/cluster/cluster-di-lite.service';
import RatesPriceHistoryAllService, { RatesPriceHistoryAllServiceS } from '@/modules/rates/price-history/rates-price-history-all.service';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS } from '@/modules/rates/price-history/rates-price-history-common.service';

import RatesDayScanBtn from '@/modules/rates/components/rates-day-scan-btn.vue';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import JsonViewer from '@/modules/common/components/ui-kit/json-viewer.vue';
import CompsetScale from '@/modules/common/components/compset-scale.vue';
import type Day from '@/modules/common/types/day.type';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import type { DialogOptions } from '@/modules/common/components/modal-wrapper.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import CURRENT_HOTEL_GRAPH_COLOR_RGB from '@/modules/common/constants/current-hotel-graph-color-rgb.constant';
import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import PopupEvents from '@/modules/events/components/popup-events.vue';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesPriceHistoryFilters from './rates-price-history-filters.vue';
import RatesPriceHistoryHeader from './rates-price-history-header.vue';
import RatesPriceHistoryTable from './rates-price-history-table.vue';
import RatesPriceHistoryTableAll from './rates-price-history-table-all.vue';
import RatesPriceHistoryTooltip from './rates-price-history-tooltip.vue';
import PriceHistoryStatistics from './rates-price-history-statistics.vue';

const ZERO_DAY = '-  0  -';

@Component({
    components: {
        CustomGraph,
        CompsetScale,
        CustomCheckbox,
        PopupEvents,
        ModalWrapper,
        LoaderWrapper,
        TogglePrices,
        JsonViewer,
        RatesPriceHistoryHeader,
        RatesPriceHistoryFilters,
        RatesPriceHistoryTable,
        RatesPriceHistoryTableAll,
        RatesPriceHistoryTooltip,
        PopupEventsContainer,
        PriceHistoryStatistics,
        RatesDayScanBtn,
    },
})
export default class RatesPriceHistoryPopup extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(UserSettingsS) private userSettingsService!: UserSettingsService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @Inject(RatesPriceHistoryServiceS) private ratesPriceHistoryService!: RatesPriceHistoryService;
    @Inject(RatesPriceHistoryAllServiceS) private ratesPriceHistoryAll!: RatesPriceHistoryAllService;
    @Inject(RatesPriceHistoryCommonServiceS) private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;
    @Inject(RatesServiceS) private ratesService!: RatesService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(ClusterRatesServiceS) private clusterRatesService!: ClusterRatesService;
    @Inject(RatesAnalysisServiceS) private ratesAnalysisService!: RatesAnalysisService;
    @Inject(ClusterDiLiteServiceS) private clusterDiLiteService!: ClusterDiLiteService;

    @Prop({ type: String, required: false })
    day!: Day;

    @Prop({ type: Object })
    modalOptions!: DialogOptions;

    @Prop({ type: Boolean, default: false })
    isCluster!: boolean;

    priceShown: PRICE_SHOWN = PRICE_SHOWN.SHOWN;
    hiddenGraphs: { [key: string]: boolean } = {};
    showRawData = false;

    tooltipFocusElement: HTMLElement | null = null;
    tooltipDay: Day | null = null;
    doc: RatesDocumentModel | RatesCompsetMainModel | RatesDocumentAllModel | null = null;

    async beforeMount() {
        await this.initData();
        this.ratesPriceHistoryCommonService.setTableDay();
        this.updatePriceShown();
    }

    mounted() {
        if (!this.doc) {
            const { modalWrapper } = this.$refs as { modalWrapper: ModalWrapper };
            modalWrapper?.triggerClose();
        }
    }

    get isDiLite() {
        return this.$route.name!.includes('.di-lite');
    }

    get isDocumentLoading() {
        if (this.isCluster) {
            return this.clusterService.storeState.loading.isLoading();
        }

        return this.ratesService.storeState.loading.isLoading();
    }

    get isPriceSwitchDisabled() {
        return !this.ratesPriceHistoryService.isNetTotalAvailable || this.isNetTotalPricesSame;
    }

    get isChartEmpty() {
        return this.chartData?.datasets
            ? this.chartData.datasets.every((dataset: any) => !dataset.data?.filter(Boolean).length)
            : true;
    }

    get isNetTotalPricesSame() {
        if (!this.document) return true;

        return this.ratesCommonService
            .isNetTotalPricesSame(this.day, this.document as RatesDocumentModel);
    }

    get isSuperadmin() {
        return this.userService.isSuperadmin;
    }

    get isNoData() {
        const { isNoData } = this.ratesPriceHistoryService;
        const { isChartEmpty } = this;

        return (isNoData || isChartEmpty) && !this.skeleton;
    }

    get isDaySoldOut() {
        const isSoldOut = this.ratesService.isSoldOut(this.day, this.currentHotelId!, this.priceShown);

        if (this.isAnalysisPage) {
            const { comparisonValues } = this.ratesAnalysisFiltersService;

            return isSoldOut || comparisonValues
                .every((_, docIndex) => this.ratesAnalysisService.isSoldOut(this.day, this.currentHotelId!, this.priceShown, docIndex));
        }

        return isSoldOut;
    }

    get isAnalysisPage() {
        return this.$route.path.includes('analysis');
    }

    get isAllChannelsMode() {
        return this.document?.providerName === 'all';
    }

    get isCheapestChannel() {
        return this.document?.providerName === 'cheapest';
    }

    get skeleton() {
        return this.ratesPriceHistoryService.storeState.loading.isLoading();
    }

    get document(): RatesDocumentModel | RatesDocumentAllModel | RatesCompsetMainModel | null {
        if (this.isCluster) {
            const { hotels } = this.clusterRatesService;
            if (!hotels) return null;

            return hotels.find(hotel => hotel.hotelId === this.hotelId!)?.compsetMain || null;
        }

        return this.ratesPriceHistoryCommonService.ratesDoc;
    }

    get compareLabel() {
        if (!this.isAnalysisPage) return '';

        const { filterComparisonName, comparisonValues } = this.ratesAnalysisFiltersService;
        const values = comparisonValues.map(value => value.name).join(', ');

        return `${this.$tc(filterComparisonName || '')} - ${values}`;
    }

    get compsetId() {
        return this.isCluster
            ? this.$route.params.compsetId
            : null;
    }

    get hotelId() {
        return this.isCluster
            ? Number(this.$route.params.hotelId)
            : null;
    }

    get lastTooltipPos() {
        return this.ratesPriceHistoryService.storeState.lastTooltipPos;
    }

    get tableDay() {
        return this.ratesPriceHistoryCommonService.dayIndex;
    }

    get choosenValues() {
        if (this.isAnalysisPage) return '';

        const { comparisonValues: values } = this.ratesAnalysisFiltersService;
        return values.map(e => e.name).join(', ');
    }

    get priceHistory() {
        const { day, hotelId, compsetId } = this;
        const { settings: DEFAULT_SETTINGS } = this.documentFiltersService.storeState;

        const compset = this.compsetsService.getCompset(compsetId);

        const documentSettings = this.isCluster && compset
            ? {
                ...DEFAULT_SETTINGS,
                compsetId: this.compsetId,
                los: compset.los.length ? compset.los[0] : null,
                pos: compset.mainPos || (compset.pos.length ? compset.pos[0] : null),
            }
            : DEFAULT_SETTINGS;

        return this.ratesPriceHistoryService
            .getPriceHistory(day, hotelId, compsetId, documentSettings);
    }

    get options() {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 4,
                    backgroundColor: 'white',
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#9B9B9B',
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#9B9B9B',
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                        callback: value => `${this.currencySymbol}${value}`,
                    },
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        } as ChartOptions;
    }

    get currentHotelId() {
        if (this.isCluster) {
            return this.hotelId;
        }
        return this.userService.currentHotelId;
    }

    get hotelColors() {
        return this.isAllChannelsMode
            ? {}
            : this.hotelsService.getHotelsGraphColor(this.compsetId);
    }

    get hotelColorsRgb() {
        return this.isAllChannelsMode
            ? {}
            : this.hotelsService.getHotelsGraphColorRgb(this.compsetId);
    }

    get chartColors() {
        return this.userSettingsService.chartColors || [];
    }

    get chartData(): ChartData | null {
        if (!this.priceHistory || !this.document) {
            return null;
        }

        if (!this.tableDay) {
            this.ratesPriceHistoryCommonService.setTableDay();
        }

        if (this.isCheapestChannel) {
            return null;
        }

        const { hotels } = this.ratesPriceHistoryService;
        const { providers } = this.ratesPriceHistoryAll;
        const { priceShown } = this;
        const hiddenHotels = Object.keys(this.hiddenGraphs);

        if (!hotels && !this.isAllChannelsMode || !providers && this.isAllChannelsMode) {
            return {
                labels: [this.$tc('noData')],
                datasets: [{}],
            };
        }

        const labels = this.priceHistory
            .map((_: any, day: number) => {
                if (day === 0) {
                    return `-  ${day}  -`;
                }

                return String(day).length === 1
                    ? `-0${day}`
                    : -day;
            })
            .reverse();

        if (this.isAllChannelsMode) {
            const datasets: ChartDataSets[] = providers!
                .map((provider: string, index) => ({
                    data: this.ratesPriceHistoryAll
                        .getPriceHistoryProvidersData(provider, priceShown) || [],

                    borderDash: provider === 'average' ? [] : [8, 4],
                    borderColor: provider === 'average'
                        ? CURRENT_HOTEL_GRAPH_COLOR
                        : this.chartColors[index] || '#000000',

                    lineTension: 0,
                    borderJoinStyle: 'round',
                    hidden: hiddenHotels.includes(provider),
                }));

            return {
                // NOTE: There is a bug in chartJs
                labels: labels.length
                    ? labels
                    : [this.$tc('noData')],
                datasets: datasets.length
                    ? datasets
                    : [{}],
            };
        }

        this.ratesPriceHistoryService.setDataKey('main');

        const hotelColors = this.hotelsService.getHotelsGraphColor(this.compsetId);

        const datasets: ChartDataSets[] = hotels!
            .map((hotel: number) => ({
                data: (this.ratesPriceHistoryService
                    .getPriceHistoryHotelPrices(hotel, priceShown) || []),
                pointBorderWidth: 1,
                borderWidth: hotel === this.currentHotelId ? 3 : 2,
                borderColor: hotel === this.currentHotelId
                    ? CURRENT_HOTEL_GRAPH_COLOR
                    : hotelColors[hotel],
                lineTension: 0,
                borderJoinStyle: 'round',
                hidden: hiddenHotels.includes(hotel.toString()),
            }));

        let dataAnalysis: ChartDataSets[] = [];

        if (this.isAnalysisPage) {
            this.comparisonValues.forEach(item => {
                this.ratesPriceHistoryService.setDataKey(item.name);

                const data: ChartDataSets[] = hotels!
                    .map((hotel: number) => ({
                        data: (this.ratesPriceHistoryService
                            .getPriceHistoryHotelPrices(hotel, priceShown) || []),
                        pointBorderWidth: 1,
                        borderWidth: hotel === this.currentHotelId ? 3 : 2,
                        borderDash: [],
                        lineTension: 0,
                        borderColor: hotel === this.currentHotelId
                            ? `rgba(${CURRENT_HOTEL_GRAPH_COLOR_RGB},0.5)`
                            : `rgba(${this.hotelColorsRgb[hotel] || '0, 0, 0'},0.5)`,
                    }));
                dataAnalysis = [...dataAnalysis, ...data];
            });
        }

        const data = datasets
            .concat(dataAnalysis);

        return {
            labels,
            datasets: data,
        };
    }

    get comparisonValues() {
        if (this.isAnalysisPage) return [];

        return this.ratesAnalysisFiltersService.comparisonValues;
    }

    get currencySymbol(): string | null {
        const { currency } = this.ratesPriceHistoryService;

        return currency
            ? this.helperService.currencySymbol(currency)
            : null;
    }

    private async initData() {
        this.doc = this.isCluster
            ? this.clusterRatesService.hotels?.find(h => h.hotelId === this.hotelId!)?.compsetMain || null
            : this.ratesService.data;

        if (!this.doc) return;

        let settings: RatesSettingsModel | null = null;

        if (this.isCluster) {
            settings = this.isDiLite
                ? this.clusterDiLiteService.settings
                : this.clusterRatesService.getSettings(this.hotelId!);
        } else {
            settings = this.ratesService.settings;
        }

        this.ratesPriceHistoryCommonService.initRatesData(this.doc!, settings);

        if (this.isCluster) {
            this.documentFiltersService.competitors = this.compsetsService.getCompset(this.compsetId)!.competitors || [];
        }
    }

    public toggleGraph(hotel: { id: string }) {
        if (this.hiddenGraphs[hotel.id]) {
            this.$delete(this.hiddenGraphs, hotel.id);
            return;
        }

        this.$set(this.hiddenGraphs, hotel.id, true);
    }

    public handleTooltipClick(newPos?: string) {
        this.ratesPriceHistoryService.storeState.lastTooltipPos = newPos || null;
    }

    private updatePriceShown() {
        this.priceShown = this.documentFiltersService.priceShown;
    }

    public setTooltipElement(el: HTMLElement) {
        this.tooltipFocusElement = el;
    }

    public setTooltipDay(label: string) {
        if (label === ZERO_DAY) {
            this.tooltipDay = 0 as Day;
            return;
        }

        this.tooltipDay = (label
            ? parseInt(label, 10)
            : null) as Day;
    }

    public setDay(label: string) {
        this.ratesPriceHistoryCommonService.setTableDay(label);
    }
}
