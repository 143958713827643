
import { Component, Vue, Prop } from 'vue-property-decorator';
import Json from 'vue-json-viewer';
import type Day from '../../types/day.type';

@Component({
    components: {
        Json,
    },
})
export default class JsonViewer extends Vue {
    @Prop({
        required: true,
    })
    value!: object | object[];

    @Prop({
        required: true,
    })
    day!: Day;

    mounted() {
        setTimeout(() => {
            this.expandDay(this.day);
        }, 0);
    }

    updated() {
        setTimeout(() => {
            this.expandDay(this.day);
        }, 0);
    }

    expandDay(day: Day) {
        const allKeyNodes = document.querySelectorAll('.jv-key');
        const checkinDateNode = Array.from(allKeyNodes).find(node => node.textContent === 'checkinDates:');
        if (!checkinDateNode) return;

        const checkinDateParentNode = checkinDateNode.parentElement;
        if (!checkinDateParentNode) return;

        const checkinDateToggleBtn = checkinDateParentNode.querySelector('.jv-toggle');
        if (!checkinDateToggleBtn) return;
        (checkinDateToggleBtn as HTMLElement).click();

        const dayNodesWrapper = checkinDateParentNode.querySelector('.jv-push');
        if (!dayNodesWrapper) return;

        setTimeout(() => {
            const dayNodes = checkinDateParentNode.querySelectorAll('.jv-node');
            const selectedDayNode = Array.from(dayNodes).find(node => node.textContent === `${day}:{}`);
            if (!selectedDayNode) return;

            const selectedDayToggleBtn = selectedDayNode.querySelector('.jv-toggle');
            if (!selectedDayToggleBtn) return;
            (selectedDayToggleBtn as HTMLElement).click();
        }, 0);
    }
}
