
import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';

import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';

import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import DayChanger from '@/modules/common/components/day-changer.vue';
import RatesScanBtn from '@/modules/common/modules/rates/components/rates-scan-btn.vue';
import Day from '@/modules/common/types/day.type';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

@Component({
    components: {
        DayChanger,
        DateDocumentFilter,
        RatesScanBtn,
    },
})
export default class RatesDemandMapHeader extends Vue {
    @Inject(ClusterRatesServiceS)
    private clusterRatesService!: ClusterRatesService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(ClusterServiceS)
    private clusterService!: ClusterService;

    @Inject(RatesServiceS)
    private ratesService!: RatesService;

    get isCluster() {
        return this.$route.name!.startsWith('cluster.');
    }

    get isDiLite() {
        return this.$route.name!.includes('.di-lite');
    }

    get day() {
        return +this.$route.params.day as Day;
    }

    get hotelId() {
        return +this.$route.params.hotelId;
    }

    get compsetId() {
        return this.$route.params.compsetId || this.documentFiltersService.compsetId;
    }

    get scanProps() {
        const { day, hotelId } = this;

        if (this.isCluster) {
            const doc = this.clusterService.getMainCompsetData<RatesCompsetMainModel>(hotelId);

            if (!doc || !doc.checkinDates) return {};

            const lastScan = this.clusterRatesService.getUpdateDate(day, hotelId);

            return {
                lastScan,
                day,
                data: doc,
                settings: this.clusterRatesService.currentSettings,
                showScanDate: true,
            };
        }

        const lastScan = this.ratesService.getUpdateDate(day);

        return {
            lastScan,
            day,
            data: this.ratesService.data,
            settings: this.ratesService.settings,
            showScanDate: true,
        };
    }

    handleScanTrigger() {
        if (this.isCluster) {
            this.clusterRatesService.triggerScan(this.day, this.compsetId!);
            return;
        }

        this.ratesService.triggerScan(this.day);
    }
}
