
import { Component, Vue, Prop } from 'vue-property-decorator';
import RatesPriceHistoryPopup from '@/modules/rates/price-history/components/rates-price-history.popup.vue';

@Component({
    components: { RatesPriceHistoryPopup },
})
export default class RatesPriceHistoryModal extends Vue {
    @Prop({ required: false })
    historyDay?: string;
}
